.nacita-footer {
  width: 100%;
  padding: 1.5rem 0;
  color: #999;
  text-align: center;
  background-color: #f9f9f9;
  border-top: .05rem solid #e5e5e5;
}
.nacita-footer p:last-child {
  margin-bottom: 0;
}
