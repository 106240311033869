#team-list {
  .team-nacita {
    text-align: center;
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 5px 5px 5px;
  }

  .social {
    margin: 20px 10px 10px 10px;
    span {
      margin: 0 5px;
    }
  }
}

.slick-prev,
.slick-next {
  &:before {
    font-size: 30px;
    color: #F05F40;
  }
}
